import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
//import Cookies from 'js-cookie'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  /* {
    path: '/sign-in',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/a* webpackChunkName: "about" *a/ '../views/Signin.vue')
  }, */
  /*{
    path: '/config',
    name: 'Config',
    component: () => import('../views/Config.vue')
  },*/
  /* {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  }, */
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../views/Imprint.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, from/* , savedPosition */) {
    if ((from.path === to.path) && to.hash) {
      return {
        el: to.hash,
        top: +110,
        behavior: 'smooth'
      }
    } else if ((from.path !== to.path) && to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            top: +110,
            behavior: 'smooth'
          })
        }, 500)
      })
    } else {
      return { top: 0, left: 0 }
    }
  }
})



/* router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Cookies.get('authorization') == undefined) {
      console.log('no');
      next('/sign-in')
    } else {
      console.log('yes');
      next()
    }
  } else {
    next()
  }
}) */

export default router
