<!--

BACKGROUND: https://unsplash.com/photos/jhJWAtwzSLM
IMAGES: https://undraw.co

-->

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }} - developedByFlip</template>
  </metainfo>

  <div id="nav" :style="[alwaysbg ? 'background-color: rgba(0, 0, 0, 0.6);' : '']">
    <div id="nav-content" @click="closeNav">
      <router-link :to="{ name: 'Home', hash: '#home' }">{{ $t('nav.home') }}</router-link>
      <router-link :to="{ name: 'Home', hash: '#services' }">{{ $t('nav.services') }}</router-link>
      <router-link :to="{ name: 'Home', hash: '#whyme' }">{{ $t('nav.whyme') }}</router-link>
      <router-link :to="{ name: 'Home', hash: '#mywork' }">{{ $t('nav.mywork') }}</router-link>
      <!-- <router-link v-if="user" :to="{ name: 'Dashboard' }" class="btn">Dashboard</router-link>
      <router-link v-if="!user" :to="{ name: 'Login' }" class="btn">{{ $t('nav.signin') }}</router-link> -->
      <router-link :to="{ name: 'Contact' }" class="btn">{{ $t('nav.contact') }}</router-link>
      <!--<router-link :to="{ name: 'Config' }" class="btn-highlight">{{ $t('nav.estimate') }}</router-link>-->
    </div>
    <button @click="openNav" id="nav-menu-btn" :style="navState ? { 'background-image': `url(${require('@/assets/svg/icons/close.svg')})` } : {}"></button>
  </div>
  <router-link :to="{ name: 'Home' }" class="top-logo">
    <img src="~@/assets/logo.png"/>
    <p>Philipp Richert</p>
  </router-link>

  <router-view v-slot="{ Component }">
    <transition :name="ifTransition" mode="out-in" appear>
      <component :is="Component" v-on:bgstat="updatenav"></component>
    </transition>
  </router-view>
  
  <!-- <transition :name="ifTransition" mode="out-in">
    <router-view v-on:bgstat="updatenav"/>
  </transition> -->
  
  <footer>
    <div class="content">
      <div class="topfooter">
        <h1>developedByFlip.</h1>
        <h2>Philipp Richert</h2>
      </div>
      <div class="middlefooter">
        <div class="footercat">
          <h3>{{ $t('footer.navigation') }}</h3>
          <router-link :to="{ name: 'Home' }">{{ $t('nav.home') }}</router-link>
          <router-link :to="{ name: 'Home', hash: '#whyme' }">{{ $t('nav.whyme') }}</router-link>
          <router-link :to="{ name: 'Contact' }">{{ $t('nav.contact') }}</router-link>
          <!--<router-link :to="{ name: 'Config' }">{{ $t('configurator.title') }}</router-link>-->
        </div>
        <div class="footercat">
          <h3>{{ $t('footer.external.title') }}</h3>
          <a href="https://gitlab.com/SparxDev" target="_blank">{{ $t('footer.external.gitlab') }}</a>
          <a href="https://github.com/SparxDev" target="_blank">{{ $t('footer.external.github') }}</a>
          <a href="mailto:contact@philipp-richert.me" target="_blank">{{ $t('footer.external.email') }}</a>
        </div>
        <div class="footercat">
          <h3>{{ $t('footer.more') }}</h3>
          <a href="https://www.linkedin.com/in/philipp-richert" target="_blank">LinkedIn</a>
          <a href="https://www.sparxdev.de/" target="_blank">sparxdev.de</a>
          <a href="https://www.philipp-richert.me/" target="_blank">philipp-richert.me</a>
        </div>
      </div>
      <div class="submiddlefooter">
        <p>Bg-image: Anthony DELANOIX – Illustrations: unDraw</p>
      </div>
      <div class="bottomfooter">
        <p>&copy; Philipp Richert 2021 – {{ $t('footer.legal.copyright') }}</p>
        <p class="legal">
          <router-link :to="{ name: 'Impressum' }">{{ $t('footer.legal.imprint') }}</router-link>
          <router-link :to="{ name: 'Privacy' }">{{ $t('footer.legal.privacy') }}</router-link>
          <!-- <router-link :to="{ name: 'Home' }">{{ $t('footer.legal.disclaimer') }}</router-link> -->
          <!-- Disclaimer: https://www.bmw.de/de/footer/metanavigation/legal-disclaimer-pool/legal-disclaimer.html -->
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
//import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      alwaysbg: false,
      navState: false
    }
  },
  /* async created() {
    const response = await axios.get(`${this.apihost}/user`, {
      headers: {
        'Authorization': this.getCookie('authorization')
      }
    });

    if (response.data.state == 'success') this.$store.dispatch('user', response.data.user)
  }, */
  computed: {
    ifTransition() {
      return (this.$route.query.origin == 'signin') && 'fade';
    },
    ...mapGetters(['user'])
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('scroll', this.scrollFunction);
    })
  },
  unmounted() {
    window.removeEventListener('scroll', this.scrollFunction);
  },
  methods: {
    openNav() {
      if (this.navState) {
        document.getElementById('nav-content').style.display = 'none';
        document.getElementById('nav-content').classList.remove('nav-opened');
        //document.getElementById('nav-menu-btn').style.backgroundImage = '~@/assets/svg/icons/menu.svg';
        this.navState = false;
      } else {
        document.getElementById('nav-content').style.display = 'flex';
        document.getElementById('nav-content').classList.add('nav-opened');
        //document.getElementById('nav-menu-btn').style.backgroundImage = '~@/assets/svg/icons/close.svg';
        this.navState = true;
      }
    },
    closeNav() {
      if (this.navState == false) return;
      document.getElementById('nav-content').style.display = 'none';
      document.getElementById('nav-content').classList.remove('nav-opened');
      this.navState = false;
    },
    scrollFunction() {
      if (this.alwaysbg) return;
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById('nav').style.backgroundColor = 'rgba(0, 0, 0, 0.6)';
      } else {
        document.getElementById('nav').style.backgroundColor = 'rgba(0, 0, 0, 0)';
      }
    },
    updatenav(v) {
      if (this.$route.query.origin == 'signin') return setTimeout(() => this.alwaysbg = false, 500);
      this.alwaysbg = v;
    },
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
  }
}
</script>

<style>
html, body, h1, h2, h3, h4, h5, h6, p { margin: 0; scroll-behavior: smooth; }
* { box-sizing: border-box; }
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #345;
  background-color: #fff;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.preform {
  white-space: pre;
}
a {
  color: #3742fa;
}
#nav {
  padding: 45px 35px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  text-align: right;
  width: 100%;
  transition: background-color 0.3s;
}
#nav a {
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  opacity: 0.7;
  transition: opacity 0.3s;
}
#nav a:not(a:last-child) {
  margin-right: 40px;
}
#nav a.btn-highlight {
  text-decoration: none;
  background-color: #3742fa;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 100%;
  padding: 15px 25px;
  opacity: 1;
  transition: background-color 0.3s;
}
#nav a.btn-highlight:hover {
  background-color: #323be2;
}
#nav a.btn {
  text-decoration: none;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 100%;
  padding: 15px 25px;
  opacity: 0.7;
  outline: 3px solid #fff;
  outline-offset: -3px;
  transition: opacity 0.3s;
}
#nav a:hover, #nav a.btn:hover {
  opacity: 1;
}
#nav-menu-btn {
  width: 45px;
  height: 45px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: transparent;
  background-image: url('~@/assets/svg/icons/menu.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  outline: 0;
  border: 0;
  cursor: pointer;
  transform: translate(-30px, 67%);
  display: none;
  z-index: 999;
}
.nav-opened {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
.nav-opened a {
  margin: 15px 0 !important;
}
.top-logo {
  text-decoration: none;
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-logo img {
  width: 100px;
  height: auto;
  filter: invert();
}
.top-logo p {
  text-decoration: none;
  color: #fff;
  letter-spacing: 5px;
  font-weight: 500;
  opacity: 0.8;
  margin-left: 30px;
}
footer {
  padding: 0 40px;
  background-color: #3742fa;
  color: rgba(255, 255, 255, 0.98);
}
footer .content {
  margin: auto;
  max-width: 1320px;
  padding: 80px 0;
}
.topfooter {
  padding-bottom: 40px;
  margin-bottom: 80px;
  border-bottom: 3px solid rgba(255, 255, 255, 0.1);
}
.topfooter h1 {
  font-size: 2.1rem;
  letter-spacing: -2px;
}
.topfooter h2 {
  letter-spacing: 6px;
}
.middlefooter {
  margin-bottom: 120px;
}
.submiddlefooter {
  margin-bottom: 20px;
}
/* .submiddlefooter p {
  opacity: 0.7; 
} */
.footercat {
  display: inline-block;
  width: 33.33%;
}
.bottomfooter p {
  display: inline-block;
}
.bottomfooter .legal {
  text-align: right;
  float: right;
}
/* .bottomfooter .legal a:not(a:first-child):not(a:last-child) {
  padding: 0 40px;
} */
.bottomfooter .legal a:not(a:last-child) {
  padding: 0 40px;
}
footer h3 {
  margin-bottom: 20px;
}
footer a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  padding: 13px 0;
}
footer a:not(.legal a) {
  display: block;
  transition: transform 0.3s;
}
footer a:not(.legal a):hover {
  transform: translateX(5px);
}
.boxError {
  background-color: #ff6b6b;
  color: #fff;
  padding: 20px 25px;
  font-weight: 700;
}

/* #nav a.router-link-exact-active {
  color: #42b983;
} */

@media screen and (max-width: 1250px) {
  #nav {
    height: 109px;
  }
  #nav-menu-btn {
    display: block;
  }
  #nav-content {
    display: none;
  }
}
@media screen and (min-width: 1250px) {
  #nav-content {
    display: block !important;
  }
  .nav-opened {
    position: static;
    top: initial;
    left: initial;
    width: initial;
    height: initial;
    display: initial;
    flex-direction: initial;
    justify-content: initial;
    align-items: initial;
    text-align: right;
    background-color: initial;
    -webkit-backdrop-filter: initial;
    backdrop-filter: initial;
  }
  .nav-opened a {
    margin: initial !important;
  }
  #nav a:not(a:last-child) {
    margin-right: 40px !important;
  }
}
@media screen and (max-width: 800px) {
  .bottomfooter p, .bottomfooter .legal {
    display: block;
    text-align: center;
  }
  .bottomfooter .legal {
    float: unset;
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .footercat {
    display: block;
    width: 100%;
  }
  .footercat:not(:last-child) {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 490px) {
  .top-logo img {
    width: 50px;
  }
  .top-logo {
    padding: 41px 30px;
  }
}
</style>
